// @flow

import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useEditor, WebsiteEditorContext } from '@shift-marketing/shift-one-website-editor'

import { Store } from 'Store'
import { fetchCampaignAndLead } from 'Actions'

import BaseLayout from 'components/MainLayout'
import Footer from 'components/Footer'
import TopBar from 'components/TopBar'
import CampaignNotFound from 'components/CampaignNotFound'
import Welcome from 'pages/Welcome'
import Landing from 'pages/Landing'

import Step1 from 'pages/Step1'
import Step2 from 'pages/Step2'
import Success from 'pages/Success'
import { generatePath, matchPath, useHistory, useLocation } from 'react-router'
import Color from 'color'
import globalPropertiesSchema from './globalProperties.schema.json'
import deepmerge from 'deepmerge'

import regular from './images/regular.svg'
import monochrome from './images/monochrome.svg'

const IS_CAMPAIGN_URL = /^\/.+$/.test(window.location.pathname)

export const AppRoute = ({ component: Component, layout: Layout = MainLayout, ...rest }) => (
    <Route {...rest} render={props => (
        <Layout>
            <Component {...props} />
        </Layout>
    )}/>
)

function MainLayout ({ children }) {
    return (
        <>
            <BaseLayout>
                <TopBar/>
                {children}
            </BaseLayout>
            <Footer/>
        </>
    )
}

const NoLayout = (props) => (<>{props.children}</>)

export function AppLoader () {
    const { state, dispatch } = React.useContext(Store)

    React.useEffect(() => {
        if (IS_CAMPAIGN_URL && state.campaign === null && state.errors.campaignNotFound !== true) {
            fetchCampaignAndLead(dispatch)
        }

        if (state.campaign) {
            document.title = `${state.campaign.client.name}`
        }
    })

    React.useEffect(() => {
        if (IS_CAMPAIGN_URL && state.campaign === null) return
        setTimeout(() => {
            document.body.classList.remove('loading')
        }, 500)
    }, [state.campaign])

    if (state.errors.campaignNotFound) {
        return <CampaignNotFound/>
    }

    if (IS_CAMPAIGN_URL && (state.campaign === null || state.lead === null)) {
        return <h1>Loading</h1>
    }

    return <App/>
}

function App () {
    const { state, dispatch } = React.useContext(Store)

    let defaultProperties = React.useCallback(global => ({
        color: {
            primaryDark: Color(global.color.primary).darken(.5).hex(),
        },
        logo: {
            regular,
            monochrome,
        },
    }), [])

    const editor = useEditor(state.campaign.websiteConfig, globalPropertiesSchema, defaultProperties)

    React.useEffect(() => {
        if (editor === null) return

        dispatch({
            type: 'SET_THEME',
            payload: deepmerge(state.defaultTheme, editor.properties)
        })

        document.body.classList.remove('loading')
    }, [state.defaultTheme, editor, dispatch])

    if (editor === null) {
        return (
            <div>loading...</div>
        )
    }

    return (
        <Suspense fallback={<div>loading...</div>}>
            <BrowserRouter>
                <AppSwitch>
                    <AppRoute exact path='/' component={Welcome} layout={NoLayout}/>
                    <AppRoute exact path="/:url" layout={MainLayout} component={Landing} editorPageTitle='Landing page'/>
                    <AppRoute exact path="/:url/step-1" layout={MainLayout} component={Step1} editorPageTitle='Step 1'/>
                    <AppRoute exact path="/:url/step-2" layout={MainLayout} component={Step2} editorPageTitle='Step 2'/>
                    <AppRoute exact path="/:url/success" layout={MainLayout} component={Success} editorPageTitle='Success'/>
                </AppSwitch>
            </BrowserRouter>
        </Suspense>
    )
}

function AppSwitch (props) {
    const { addPage } = React.useContext(WebsiteEditorContext)
    const history = useHistory()
    const location = useLocation()

    // Find the page that matches the current location
    const matchedPage = props.children.find(p => matchPath(location.pathname, {
        path: p.props.path,
        exact: p.props.exact,
        strict: p.props.strict
    }) !== null)

    // extract the :url value
    const { params: { url } } = matchPath(location.pathname, {
        path: matchedPage.props.path // we assume the first page is the landing page
    })

    React.useEffect(() => {
        for (const page of props.children) {
            if (!page.props.editorPageTitle) continue

            addPage(
                page.props.editorPageTitle,
                function () {
                    history.push(generatePath(page.props.path, { url }))
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, addPage, history])

    return <Switch>{props.children}</Switch>
}
