// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import { createClickActivity } from 'Actions'
import { Store } from 'Store'
import { ImageBlock, TextBlock, useBlock, PARAGRAPH_SMALL } from '@shift-marketing/shift-one-website-editor'

import formatPhoneNumber from 'util/formatPhoneNumber'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'
import propertiesSchema from './properties.schema.json'

type ImagePath = string;

type Props = {
    theme: {
        background: string,
        color: string,
        logo: ImagePath,
    }
}

const Footer = (props: Props) => {
    const { state } = React.useContext(Store)
    const { properties, ref } = useBlock('footer', propertiesSchema)
    const address = state.campaign.client.address

    const formattedAddress = [
        ((address.line1 || '') + ' ' + (address.line2 || '')).trim(),
        address.city,
        address.province,
        address.postalCode
    ].join(' ')
    const formattedPhone = state.campaign.client.phone
        ? ` | phone: ${formatPhoneNumber(state.campaign.client.phone)}` : null
    const formattedEmail = state.campaign.client.email
        ? ` | email: ${state.campaign.client.email}` : null

    return (
        <div ref={ref} className={styles.container} style={{
            backgroundColor: properties.backgroundColor,
        }}>
            <div className={styles.logo}>
                <ImageBlock
                    blockId="footer.logo"
                    defaultImage="$.logo.monochrome"
                    resize="both"
                />
            </div>

            <div className={styles.disclaimer}>
                <TextBlock
                    blockId={'footer.info'}
                    defaultContent={`
                        <p class="${PARAGRAPH_SMALL}">
                            ${formattedAddress}
                            
                            ${formattedPhone}
                            
                            ${formattedEmail}
                        </p>
                    `}
                    onAnchorClick={createClickActivity}
                />
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Footer))
