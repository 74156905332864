// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { createClickActivity } from 'Actions'
import { Store } from 'Store'
import withTheme from 'hoc/withTheme'
import StepLayout from 'components/StepLayout'
import Countdown from 'components/Countdown'
import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm from 'components/Form/Helpers/FormHOC'
import propertiesSchema from './properties.schema.json'

import styles from './styles.module.scss'

import supportedThemes from './themes/__supportedThemes'
import { TextBlock, useBlock } from '@shift-marketing/shift-one-website-editor'

Landing.defaultProps = {
    date: new Date()
}

const DEFAULT_HEADER_MESSAGE = `
    <h3>{firstName}, thank you for being a part of their story.</h3>
    <p>Support from alumni, community and corporate sponsors have provided countless opportunities for our students to thrive.</p>  
`

function Landing (props: Props) {
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    const { properties } = useBlock('landing.header', propertiesSchema)

    return (
        <StepLayout>
            {state.theme.countdown.visibility && (
                <div className={styles.expiryText}>
                    <Countdown date={state.campaign.expiryDate}/>
                </div>
            )}

            <div className={styles.message}>
                <TextBlock
                    className={styles.headerMessage}
                    style={{
                        '--heading-color': properties.headingColor,
                        '--color': properties.bodyColor
                    }}
                    blockId="masthead.message"
                    values={{ ...state.lead.fields }}
                    valuesSchema={state.campaign.mergeTags}
                    locale={state.locale}
                    defaultContent={DEFAULT_HEADER_MESSAGE}
                    onAnchorClick={createClickActivity}
                />
            </div>

            <Form.SubmitBlock
              isLoading={props.isLoading}
              blockId={'landing.submit'}
              label={t(props.theme.buttonTransKey)}
            />
        </StepLayout>
    )
}

export default withForm(true, false)(withTheme(supportedThemes)(Landing))
